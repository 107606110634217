import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  // AddModeratorResponse,
  // Moderator,
  // ModeratorRequest,
  // ModeratorResponse,
  ModeratorRole,
  ModeratorRoleResponse,
} from './api.user.model';
import { environment } from 'src/environments/environment';

export class UserApi {
  private apiUrl: string = environment.BE_URL + 'user';

  constructor(public readonly http: HttpClient) {}

  // getUserById(id: string): Observable<UserInfoResponse> {
  //   return this.http.get<any>(this.apiUrl + '/users/' + id).pipe(
  //     timeout(this.config.responseTimeout),
  //     map((res: any) => {
  //       return res;
  //     }),
  //     catchError((error) => {
  //       throw error.error;
  //     }),
  //   );
  // }

  // getTotalsUser(roles: any, statuses: any, keyword: string) {
  //   return this.http.get<UserModel[]>(this.apiUrl + '/users').pipe(
  //     timeout(this.config.responseTimeout),
  //     map((res: any) => {
  //       const users = res.data;
  //       res.data = users.filter((item: any) => {
  //         if (keyword.trim() !== '') {
  //           return (
  //             (item.last_name?.toLowerCase().includes(keyword.toLowerCase()) ||
  //               item.first_name
  //                 ?.toLowerCase()
  //                 .includes(keyword.toLowerCase()) ||
  //               item.email?.toLowerCase().includes(keyword.toLowerCase()) ||
  //               item.register_date
  //                 ?.toLowerCase()
  //                 .includes(keyword.toLowerCase())) &&
  //             (roles && roles.length > 0
  //               ? roles.some((element: any) => item.roles.includes(element))
  //               : true) &&
  //             (statuses && statuses.length > 0
  //               ? statuses.includes(item.status)
  //               : true)
  //           );
  //         } else {
  //           return (
  //             (roles && roles.length > 0
  //               ? roles.some((element: any) => item.roles.includes(element))
  //               : true) &&
  //             (statuses && statuses.length > 0
  //               ? statuses.includes(item.status)
  //               : true)
  //           );
  //         }
  //       });
  //       return res.data;
  //     }),
  //     catchError((error) => {
  //       throw error.error;
  //     }),
  //   );
  // }

  // updateUser(userId: string, userDetail: Partial<any>): Observable<any> {
  //   return this.http
  //     .put<any>(this.apiUrl + '/users/' + userId, userDetail)
  //     .pipe(
  //       timeout(this.config.responseTimeout),
  //       map((res: any) => {
  //         return res.data;
  //       }),
  //       catchError((error) => {
  //         throw error.error;
  //       }),
  //     );
  // }

  // delete(id: string) {
  //   return this.http.delete<User>(this.apiUrl + '/users/' + id).pipe(
  //     timeout(this.config.responseTimeout),
  //     catchError((error) => {
  //       throw error.error;
  //     }),
  //   );
  // }

  // getUserManagementReport(): Observable<UserManagementReport> {
  //   return this.http
  //     .get<any>(this.apiUrl + '/admin/user-management-report')
  //     .pipe(
  //       timeout(this.config.responseTimeout),
  //       map((res: any) => {
  //         if (res.success) {
  //           return res.data;
  //         }
  //       }),
  //       catchError((error) => {
  //         throw error.error;
  //       }),
  //     );
  // }

  // getUserManagement(
  //   pageNum: number,
  //   pageSize: number,
  //   textSearch?: string,
  // ): Observable<UserManagementResponse> {
  //   let url = `/admin/search?pageNum=${pageNum}&pageSize=${pageSize}`;
  //   if (textSearch) url = url + `&textSearch=${textSearch}`;
  //   return this.http.get<UserManagementResponse>(this.apiUrl + url).pipe(
  //     timeout(this.config.responseTimeout),
  //     map((res: UserManagementResponse) => {
  //       return res;
  //     }),
  //     catchError((error) => {
  //       throw error.error;
  //     }),
  //   );
  // }

  // getCountries(): Observable<any> {
  //   return this.http
  //     .get<any>(`${environment.API_URL}/booking-generic/country/list`)
  //     .pipe(
  //       timeout(this.config.responseTimeout),
  //       map((res: any) => {
  //         return res.data;
  //       }),
  //       catchError((error) => {
  //         throw error.error;
  //       }),
  //     );
  // }

  // getModerateRole(body: ModeratorRequest): Observable<string> {
  //   return this.http.post<any>(`${this.apiUrl}/admin/role`, body).pipe(
  //     timeout(this.config.responseTimeout),
  //     map((res: any) => {
  //       return res.data.role;
  //     }),
  //     catchError((error) => {
  //       throw error.error;
  //     }),
  //   );
  // }

  // addModerator(body: ModeratorRequest): Observable<Moderator[]> {
  //   return this.http
  //     .post<AddModeratorResponse>(`${this.apiUrl}/admin/moderator`, body)
  //     .pipe(
  //       timeout(this.config.responseTimeout),
  //       map((res) => {
  //         return res.data;
  //       }),
  //       catchError((error) => {
  //         throw error.error;
  //       }),
  //     );
  // }

  // getModerators(
  //   pageNum: number,
  //   pageSize: number,
  //   nodeId: string,
  //   param: ModeratorRequest,
  // ): Observable<ModeratorResponse> {
  //   const url = `${this.apiUrl}/admin/moderators?pageNum=${pageNum}&pageSize=${pageSize}&nodeId=${nodeId}&countryCode=${param.country_code}&zipCode=${param.zip_code}&type=${param.type}&typeModerator=${param.type_moderator}`;
  //   return this.http.get<ModeratorResponse>(url).pipe(
  //     timeout(this.config.responseTimeout),
  //     map((res) => {
  //       return res;
  //     }),
  //     catchError((error) => {
  //       throw error.error;
  //     }),
  //   );
  // }

  getModeratorRole(): Observable<ModeratorRole[]> {
    return this.http
      .get<ModeratorRoleResponse>(`${this.apiUrl}/admin/roles/module`)
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((error) => {
          throw error.error;
        }),
      );
  }
}
