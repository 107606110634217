import { NavigationItem } from '../models/interfaces/navigation';
import { SoctripIcons } from './soctrip-icon.enum';

export const MenuSidebar: NavigationItem[] = [
  {
    path: 'dashboard',
    title: 'dashboard',
    icon: SoctripIcons.BAR_CHART_SQUARE_01,
  },
  {
    path: 'restaurant-management',
    title: 'restaurant-management',
    icon: SoctripIcons.BUILDING_02,
  },
  {
    path: 'reservation-management',
    title: 'reservation-management',
    icon: SoctripIcons.FILE_05,
  },
  {
    path: 'bill-management',
    title: 'bill-management',
    icon: SoctripIcons.RECEIPT,
  },
  {
    path: 'banner-management',
    title: 'banner-management',
    icon: SoctripIcons.IMAGE_INDENT_LEFT,
  },
];
