import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() isSmallScreen: boolean = false;
  @Input() user: any;
  @Output() openSidebar: EventEmitter<boolean> = new EventEmitter();
  environmentURL?: string = environment.FE_URL_SALE;
  environment = environment;
  translator = inject(CustomTranslateService);
  sanitizer = inject(DomSanitizer);
  shopURL?: string;
  iframeUrl: SafeResourceUrl;
  appLogo = `../assets/imgs/logo/${environment.APP_LOGO}.svg`
  ngOnInit(): void {
    this.shopURL = `${this.environmentURL}shopping`;
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.IFRAME_CONNECT_URL,
    );
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  handleOnLoad = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage({ type: 'connect' }, '*');
    }
  };
  changeLanguage(language: string) {
    this.translator.setLanguage(language);
  }

  handleSignOut = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: 'disconnect',
        data: {
          token: localStorage.getItem('accessToken'),
          user_id: localStorage.getItem('userId'),
        },
      };
      frame.contentWindow.postMessage(messageData, '*');
    }
    localStorage.clear();
    window.location.href = `${environment.LOGIN_URL}/login?r=${environment.FE_URL}`;
  };
}
