<div class="bg-gray-100 bg-cover h-screen object-contain rounded-2xl flex">
  <div class="flex flex-1 flex-col items-end justify-center">
    <div class="flex-container">
      <div
        class="flex flex-1 flex-col justify-center md:w-80 lg:w-[595px] max-w-[30vw] h-full gap-5"
      >
        <span
          class="font-bold md:text-3xl lg:text-[3vw] lg:leading-[3.2vw] text-primary-900"
        >
          Welcome to Soctrip Finance Admin
        </span>
        <span
          class="md:text-sm lg:text-[1.5vw] lg:leading-[1.8vw] text-gray-400"
          >Travel, Connect, Remember - Love's Moments Forever</span
        >
        <img
          id="login-img"
          class="max-w-[80%] max-h-[70vh]"
          [src]="imageUrl.src"
          alt="login"
        />
      </div>
    </div>
  </div>
  <div class="flex flex-1 items-center justify-center">
    <div
      class="max-w-[510px] w-full flex flex-col px-8 py-12 bg-white justify-center items-center rounded-lg"
    >
      <img src="/assets/imgs/logo/logo.svg" alt="logo" class="w-16 h-16" />
      <h2 class="text-2xl font-bold mt-3">Sign In</h2>
      <div
        class="rounded-lg border h-12 border-solid px-3 py-3.5 w-full border-orange-dark-300 mt-4 flex gap-2 items-center"
        *ngIf="errorMessage"
      >
        <i class="pi pi-exclamation-circle" [style]="{ color: '#b42318' }"></i>
        <span class="font-semibold text-error-700 text-sm">{{
          errorMessage
        }}</span>
      </div>
      <form [formGroup]="loginForm" class="flex flex-col w-full gap-5 mt-8">
        <div *ngIf="signInMethod === 'phone'" class="flex flex-col gap-1">
          <label class="text-gray-700 font-medium">Phone number</label>
          <span
            class="flex w-full justify-between rounded-lg border-2 gap-1 border-gray-300 items-center"
          >
            <div class="login-phone-dropdown">
              <p-dropdown
                [options]="countries"
                [(ngModel)]="selectedCountry"
                (onChange)="onDropDownChange($event)"
                [ngModelOptions]="{ standalone: true }"
                optionLabel="code"
                [filter]="true"
                filterBy="name"
              >
                <ng-template pTemplate="selectedItem">
                  <div
                    *ngIf="selectedCountry"
                    class="flex gap-2 justify-center items-center"
                  >
                    <img [src]="getIcon(selectedCountry)" style="width: 18px" />
                    <div>{{ selectedCountry.code }}</div>
                  </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                  <div class="flex gap-2 items-center">
                    <img [src]="getIcon(country)" style="width: 18px" />
                    <span>{{ country.name }}</span>
                    <span>({{ country.code }})</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <input
              pInputText
              id="sign-up-username-input"
              type="text"
              name="username"
              formControlName="phone"
              placeholder="Enter your phone number"
              class="login-phone-input"
              maxlength="20"
            />
          </span>
        </div>
        <span class="flex flex-col gap-1" *ngIf="signInMethod === 'email'">
          <label
            htmlFor="login-username-input"
            class="text-gray-700 font-medium"
            >Email</label
          >
          <input
            pInputText
            id="login-username-input"
            placeholder="e.g.your@email.com"
            formControlName="email"
          />
        </span>
        <span class="flex flex-col gap-[2px] relative">
          <label
            htmlFor="login-password-input"
            class="text-gray-700 font-medium"
            >Password
          </label>

          <p-password
            [toggleMask]="true"
            [feedback]="false"
            placeholder="e.g., ***********"
            formControlName="password"
            styleClass="w-full"
            inputStyleClass="w-full"
          ></p-password>
        </span>
        <span
          class="py-2 px-3 h-5 text-[#1570ef] flex justify-center font-semibold text-sm cursor-pointer"
          >Forgot password?</span
        >
        <p-button
          label="Sign in"
          type="submit"
          [styleClass]="'w-full flex justify-center'"
          (click)="onLoginSubmit()"
          [loading]="logging"
          iconPos="right"
        ></p-button>
        <span
          class="py-2 px-3 h-5 text-[#1570ef] flex justify-center font-semibold text-sm cursor-pointer"
          [style]="{ color: '#1570ef', height: '20px' }"
          (click)="changeSignInMethod()"
          >Sign in by
          {{ signInMethod === "email" ? "phone number" : "email" }}</span
        >
        <span
          class="flex justify-center items-center gap-1 text-gray-600 h-5 text-sm cursor-pointer"
          >Don't have an account?
          <a class="text-primary-600 font-semibold" routerLink="/sign-up"
            >Sign up</a
          ></span
        >
      </form>
    </div>
  </div>
</div>
