import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { WelcomeLayoutComponent } from './shared/layouts/welcome-layout/welcome-layout.component';
import { NotFoundComponent } from './shared/pages/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeLayoutComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'restaurant-management',
        loadChildren: () =>
          import(
            './modules/restaurant-management/restaurant-management.module'
          ).then((m) => m.RestaurantManagementModule),
      },
      {
        path: 'reservation-management',
        loadChildren: () =>
          import(
            './modules/reservation-management/reservation-management.module'
          ).then((m) => m.ReservationManagementModule),
      },
      {
        path: 'bill-management',
        loadChildren: () =>
          import('./modules/bill-management/bill-management.module').then(
            (m) => m.BillManagementModule
          ),
      },
      {
        path: 'banner-management',
        loadChildren: () =>
          import('./modules/banner-management/banner-management.module').then(
            (m) => m.BannerManagementModule,
          ),
      },

      {
        path: 'not-found',
        pathMatch: 'full',
        component: NotFoundComponent,
      },
      {
        path: '**',
        redirectTo: 'dashboard',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
