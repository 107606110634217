<div
  class="flex h-screen w-full items-center justify-center flex-col bg-[url('/assets/imgs/background.png')]"
>
  <img src="/assets/imgs/404.png" alt="" />
  <div class="text-2xl font-semibold mt-10">Uh-Oh! 404 Page not found</div>
  <div class="mt-10">
    <a
      class="text-white bg-blue-600 rounded-lg flex items-center gap-2 justify-center px-5 py-3 hover:bg-blue-700 text-sm"
      routerLink="/"
      >Back to home
      <i class="sctr-icon-arrow-narrow-right text-base"></i>
    </a>
  </div>
</div>
