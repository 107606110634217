<router-outlet></router-outlet>
<app-toast-message></app-toast-message>

<a
  *ngIf="environment.state !== 'DEVELOPMENT' && environment.state !== 'LOCAL'"
  href="https://forms.gle/eX9Jb2eDMPuw6Hky9"
  target="_blank"
  class="fixed bottom-20 right-10 flex items-center gap-2 px-1 py-1 rounded-full shadow-lg border border-gray-100 cursor-pointer bg-white hover:bg-gray-25 group"
>
  <span class="text-xs font-semibold group-hover:ml-2 hidden group-hover:block"
    >Give feedback</span
  >
  <img class="h-10 w-10" src="/assets/imgs/logo/logo.svg" alt="Logo Soctrip" />
</a>

<iframe
  style="display: none"
  id="socialLogin"
  [src]="iframeUrl"
  title="socialLogin"
  (load)="handleOnLoad()"
></iframe>
