import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { CustomTranslateService } from './core/services/custom-translate.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
})
export class AppComponent {
  smallScreen = 1439;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;
  environment = environment;
  environmentURL?: string = environment.FE_URL_SALE;
  shopURL?: string;
  iframeUrl: SafeResourceUrl;

  constructor(
    private primeNGConfig: PrimeNGConfig,
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService,
    private translator: CustomTranslateService
  ) {}

  ngOnInit(): void {
    this.shopURL = `${this.environmentURL}shopping`;
    this.iframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      `${environment.ADMIN_HUB_URL}/app-connect`
    );

    this.onConfigTranslate();
  }

  onConfigTranslate() {
    this.primeNGConfig.setTranslation({
      firstDayOfWeek: 1,
    });
    
    const languages = ['en', 'vi'];
    const language = localStorage.getItem('lang') || '';

    if(languages.includes(language)) {
      this.translator.setLanguage(language);
    } else {
      this.translator.setLanguage('en');
    }

    this.translator.language$.subscribe((lang) => {
      if (languages.includes(lang)) {
        this.translate(lang);
      } else {
        this.translate('en');
      }
    });
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe((res) => {
      this.primeNGConfig.setTranslation(res);
    });
  }

  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  handleOnLoad = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage({ type: 'connect' }, '*');
    }
  };
}
