import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { ModerateRole } from 'src/app/core/constants/role.config';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { AuthAPI } from 'src/app/lib/api/auth/api.auth.model';

const WELCOME_IMAGES = [
  { src: '/assets/imgs/trip.svg', text: 'SIGNIN_SIGNUP.WELCOME.SLOGAN_2' },
  {
    src: '/assets/imgs/honey_moon.svg',
    text: 'SIGNIN_SIGNUP.WELCOME.SLOGAN_1',
  },
];

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
  ) {}

  errorMessage = '';
  loginForm = new FormGroup({
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  countries: any[] = [];
  selectedCountry: any;
  signInMethod = 'email';
  currentYear = new Date().getFullYear();
  currentIndex = 0;
  imageUrls = WELCOME_IMAGES;
  imageUrl = this.imageUrls[0];
  logging = false;

  accessibleRole = [ModerateRole.SUPER_ADMIN.code, ModerateRole.ADMIN.code];

  ngOnInit(): void {
    // this.countries = countryCode;
    this.selectedCountry = this.countries[0];
    this.changeImage();
    setInterval(() => this.changeImage(), 5000);
  }

  changeImage(): void {
    this.currentIndex = (this.currentIndex + 1) % this.imageUrls.length;
    this.imageUrl = this.imageUrls[this.currentIndex];
  }

  onDropDownChange(event: any) {
    this.selectedCountry = event.value;
  }

  getIcon(country: any) {
    return country.icon;
  }

  changeSignInMethod() {
    this.signInMethod = this.signInMethod === 'email' ? 'phone' : 'email';
    this.errorMessage = '';
  }

  onLoginSubmit(): void {
    const { email, phone, password } = this.loginForm.getRawValue();
    this.displayErrorMessage(email, phone, password);
    if (this.errorMessage) {
      return;
    }
    const phoneOrEmail = this.signInMethod === 'email' ? email : phone;
    const areaCode =
      this.signInMethod === 'phone' ? this.selectedCountry.code : '';
    const credentials: AuthAPI.SignIn = {
      phone_or_email: phoneOrEmail,
      password,
      area_code: areaCode,
      device: '',
    };
    this.logging = true;
    this.authService
      .login(credentials)
      .pipe(
        switchMap((res) => {
          if (res.success) {
            localStorage.setItem('accessToken', res.data.access_token);
            localStorage.setItem('user_profile', JSON.stringify(res.data.user));
            return this.userService.getModeratorRole();
          } else {
            this.errorMessage = res.error.message;
            this.logging = false;
            return of(undefined);
          }
        }),
      )
      .subscribe({
        next: (response) => {
          if (response) {
            const data = response?.[0];
            if (data && this.accessibleRole.includes(data.role)) {
              localStorage.setItem('STATE', 'true');
              localStorage.setItem('ROLE', data.role);
              this.router.navigate(['/']);
            } else {
              this.errorMessage =
                'You do not have permission to access this page';
              localStorage.removeItem('accessToken');
              localStorage.removeItem('user_profile');
              this.logging = false;
            }
          }
        },
        error: (error) => {
          this.logging = false;
          if (Object.prototype.hasOwnProperty.call(error, 'error')) {
            this.errorMessage = error.error.message;
          }
        },
      });
  }

  displayErrorMessage(
    email: string | null,
    phone: string | null,
    password: string | null,
  ): void {
    if (!phone && this.signInMethod === 'phone') {
      this.errorMessage = 'Phone number required for login.';
    } else if (!email && this.signInMethod === 'email') {
      this.errorMessage = 'Email required for login.';
    } else if (!password) {
      this.errorMessage = 'Password required for login.';
    } else {
      this.errorMessage = '';
    }
  }
}
