export enum SummaryIdEnum {
  SALES = 'sales',
  TOTAL_ORDERS = 'total_orders',
  CANCELLED_ORDERS = 'cancelled_orders',
  CONVERSION_RATE = 'conversion_rate',
  VISITORS = 'visitors',
  SHOP_VIEWS = 'shop_views',
  SALE_PER_ORDER = 'sale_per_order',
  REFUND_ORDERS = 'refund_orders',
  REFUND_SALES = 'refund_sales',
  BUYER = 'buyer',
  SALE_PER_BUYER = 'sale_per_buyer',
}

export enum ComponentUnitEnum {
  PRICE = 'price',
  PERCENT = 'percent',
  NORMAL = 'normal',
}

export enum RankingTabEnum {
  RESERVATION = 'TOTAL_RESERVATIONS',
  REVENUE = 'REVENUE',
  VIEW = 'VIEW',
}

export enum StatisticEnum {
  TOTAL_RESTAURANTS = 'TOTAL_RESTAURANTS',
  TOTAL_RESERVATION = 'TOTAL_RESERVATION',
  TOTAL_DEPOSIT = 'TOTAL_DEPOSIT',
  RESTAURANT_VIEWS = 'RESTAURANT_VIEWS',
  RESERVATIONS_PER_RESTAURANT = 'RESERVATIONS_PER_RESTAURANT',
  NUMBER_OF_CATEGORIES = 'NUMBER_OF_CATEGORIES',
  CANCELLED_RESERVATION = 'CANCELLED_RESERVATION',
  NUMBER_OF_CHECK_INS = 'NUMBER_OF_CHECK_INS',
  CONVERSION_RATE = 'CONVERSION_RATE',
}
