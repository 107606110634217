import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import {
  catchError,
  map,
  Observable,
  throwError,
  BehaviorSubject,
  tap,
  switchMap,
  filter,
  take,
} from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set(
      'Authorization',
      `Bearer ${localStorage.getItem('accessToken')}`,
    );
    const authReq = request.clone({ headers });
    return next.handle(authReq).pipe(
      map((event) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == HttpStatusCode.Unauthorized) {
          return this.handle401Error(authReq, next);
        }
        return throwError(() => error);
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshToken$.next(null);
      const refreshToken = localStorage.getItem('r');
      if (refreshToken && refreshToken !== 'undefined') {
        return this.authService.refreshToken(refreshToken).pipe(
          tap(({ accessToken }) => {
            this.isRefreshing = false;
            localStorage.removeItem('accessToken');
            localStorage.setItem(accessToken, accessToken);
            this.refreshToken$.next(accessToken);
          }),
          switchMap(({ accessToken }) => {
            return next.handle(this.addTokenHeader(request, accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            return throwError(() => err);
          }),
        );
      } else {
        this.isRefreshing = false;
        this.logOut();
      }
    }
    return this.refreshToken$.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => {
        return next.handle(this.addTokenHeader(request, token));
      }),
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
