import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthAPI } from '../auth/api.auth.model';
import { environment } from 'src/environments/environment';

export class AuthApi {
  private apiUrl: string = environment.BE_URL;
  AUTH = 'auth';

  constructor(public readonly http: HttpClient) {}

  signIn(data: AuthAPI.SignIn): Observable<AuthAPI.SignIn> {
    return this.http
      .post<AuthAPI.SignIn>(`${this.apiUrl}${this.AUTH}/signin`, data)
      .pipe(
        catchError((error) => {
          throw error.error;
        }),
      );
  }

  signOut(data: AuthAPI.SignOut): Observable<AuthAPI.SignOut> {
    return this.http
      .post<AuthAPI.SignOut>(`${this.apiUrl}${this.AUTH}/signout`, data)
      .pipe(
        catchError((error) => {
          throw error.error;
        }),
      );
  }

  refreshToken(refreshToken: any): Observable<any> {
    // TODO: Replace with actual code
    return this.http.post(`${this.apiUrl}${this.AUTH}/refresh`, refreshToken);
  }
}
