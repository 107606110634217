// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './interface/IEnvironment.interface';
import { PREFIX, SERVICES } from './path-consts/prefix.env';

export const environment: IEnvironment = {
  state: 'LOCAL',
  FE_URL: `https://tmtrav-food-admin-${PREFIX.DEV}.tma-swerp.com/`,
  FE_URL_SALE: `https://tmtrav-ecommerce-${PREFIX.DEV}.tma-swerp.com/`,
  URL_RESTAURANT: `https://tmtrav-food-${PREFIX.DEV}.tma-swerp.com/`,
  BE_URL: `https://api-${PREFIX.DEV}-tmtrav.tma-swerp.com/`,
  IFRAME_CONNECT_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com/app-connect`,
  LOGIN_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com`,
  SERVICES: SERVICES,
  APP_TITLE: 'TMTravel',
  APP_LOGO: 'TMTravel-white',
  ADMIN_HUB_URL: 'https://admin-dev.tma-swerp.com',
  IFRAME_BANNER_MANAGEMENT: `https://tmtrav-banner-${PREFIX.DEV}.tma-swerp.com/restaurant`
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
