import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiClient } from 'src/app/lib/api/api-client';
import {
  // Moderator,
  // ModeratorRequest,
  // ModeratorResponse,
  ModeratorRole,
} from 'src/app/lib/api/user/api.user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userInfo = new BehaviorSubject<any>({});

  private indexTab: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  getIndexTab(): Observable<number> {
    return this.indexTab.asObservable();
  }

  setIndexTab(newState: number): void {
    this.indexTab.next(newState);
  }

  private api: ApiClient;
  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http);
  }

  // getUserById(id: string): Observable<UserInfoResponse> {
  //   return this.api.user.getUserById(id);
  // }

  // getTotalsUser(roles: any, statuses: any, keyword: string) {
  //   return this.api.user.getTotalsUser(roles, statuses, keyword);
  // }

  // getById(id: string): Observable<any> {
  //   return this.http.get<any>('assets/mock/user-by-id.json');
  // }

  // updateUser(userId: string, userDetail: Partial<any>): Observable<any> {
  //   return this.api.user.updateUser(userId, userDetail);
  // }

  // delete(id: string) {
  //   return this.api.user.delete(id);
  // }

  // getUserManagementReport(): Observable<UserManagementReport> {
  //   return this.api.user.getUserManagementReport();
  // }

  // getUserManagement(
  //   pageNum: number,
  //   pageSize: number,
  //   textSearch?: string,
  // ): Observable<UserManagementResponse> {
  //   return this.api.user.getUserManagement(pageNum, pageSize, textSearch);
  // }

  // getCountries(): Observable<any> {
  //   return this.api.user.getCountries();
  // }

  // getModerateRole(body: ModeratorRequest): Observable<string> {
  //   return this.api.user.getModerateRole(body);
  // }

  // addModerator(body: ModeratorRequest): Observable<Moderator[]> {
  //   return this.api.user.addModerator(body);
  // }

  // getModerators(
  //   pageNum = 0,
  //   pageSize = 10,
  //   nodeId: string,
  //   param: ModeratorRequest,
  // ): Observable<ModeratorResponse> {
  //   return this.api.user.getModerators(pageNum, pageSize, nodeId, param);
  // }

  getModeratorRole(): Observable<ModeratorRole[]> {
    return this.api.user.getModeratorRole();
  }
}
