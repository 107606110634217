import { HttpClient } from '@angular/common/http';
import { AuthApi } from './auth/api.auth';
import { UserApi } from './user/api.user';

export class ApiClient {
  readonly auth: AuthApi;
  readonly user: UserApi;

  constructor(private http: HttpClient) {
    this.auth = new AuthApi(this.http);
    this.user = new UserApi(this.http);
  }
}
