export const PREFIX = {
  API_V1: 'api/v1',
  AVATAR_DIR: 'sso-service/static/images/avatars',
  DEV: 'dev',
  PROD: 'production',
  QA: 'qa',
  LOCAL: 'local',
};

export const SERVICES = {
  STOCK: 'stock',
  CATALOG: 'catalog',
  SHOP: 'shop',
  STORAGE: 'storage',
  STORAGE_STREAMING: 'storage-streaming',
  ORDER: 'order',
  PAYMENT: 'payment',
  SHIPMENT: 'shipment',
  ECOMMERCE_FINANCE: 'ecommerce-finance',
  ECOMMERCE_WALLET: 'ecommerce-wallet',
};
